import React from "react";
import mail from "../media/envelope-solid.png"
import github from "../media/github.png"
import linkedin from "../media/linkedin.png"


function Footer() {
    return (

        <div class="footer">
            <div style={{"padding":"2rem 0"}}>
                <p>Contact Details:</p>
                <p>Write us at: odiyaparivar@gmail.com</p>
                
            </div>
            <div >
                <p style={{"fontWeight":"700"}}>Developers</p>
                <p>Rishav Kapoor 
                    <a href="mailto:rishavkapoorrk@gmail.com"><img className="footer-img"src={mail} /></a>
                    <a href="https://github.com/rishavkapoor9"><img className="footer-img"src={github} /></a>
                    <a href="https://www.linkedin.com/in/rishav-kapoor-a58b66251/"><img className="footer-img"src={linkedin} /></a></p>
                <p>Sakshi Kumari
                <a href="mailto:s_kumari@cy.iitr.ac.in"><img className="footer-img"src={mail} /></a>
                    <a href="https://github.com/sakshijha13/"><img className="footer-img"src={github} /></a>
                    <a href="https://www.linkedin.com/in/sakshi-kumari-828121238?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><img className="footer-img"src={linkedin} /></a>
                </p>
                <p>Nidhi Sharma</p>
                
            </div>
            
            
        </div>

    )
}

export default Footer;