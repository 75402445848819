import React from 'react'
import p1 from "../media/people/1.jpg"


const OrganizerCard = ({ img, name, dept, email, size }) => {
    return (

        <div className={(size=="large")?'or-card':'sm-or-card'}>
            <div className='top'>
                <img className={(size=="large")?'img':'sm-img'} src={img?img:p1}></img>
            </div>
            <div>
                <hr></hr>
            </div>

            <div className={(size=="large")?'bottom':'sm-bottom'}>
                <p className={(size=="large")?'card-name':'sm-card-name'}>{name}</p>
                
            </div>


        </div>


    )
}

export default OrganizerCard