import React from "react";
import lj from "../media/odisha/odia parivar logo.png";
import jt from "../media/odisha/Jagannath Temple.jpg"

function Navbar() {
    return (
        <div>

            <nav class="navbar navbar-default">

                <div class="container">
                    <div>
                        <img className="navbar-image1" src={lj} />
                    </div>
                    <div className="nav-center">
                        <div class="nav-center-items ">


                            <p className="nav-heading">ODIA PARIVAR ROORKEE</p>
                        </div>
                        <div className="nav-center-items">
                            <ul class=" nav navbar-nav navbar-right">

                                <li id="home"><a href="/">HOME</a></li>
                                <li id="about"><a href="/people">PEOPLE</a></li>
                                <li id="contact"><a href="/events">EVENTS</a></li>
                                <li id="contact"><a href="/gallery">GALLERY</a></li>
                                <li id="contact"><a href="/organizers">ORGANIZERS</a></li>


                            </ul>
                        </div>

                    </div>
                    <div>
                        <img className="navbar-image2" src={jt} />
                    </div>


                </div>
            </nav>
        </div>

    )
}

export default Navbar;