import React from 'react';
import "./styles.css";
import Slider from "react-slick";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import gp1 from "../media/events/Ganesh puja 2023-20240328T105134Z-001/20230919_221938.jpg"
import gp2 from "../media/events/Ganesh puja 2023-20240328T105134Z-001/20230919_222026.jpg"

const Event2 = () => {
    function SampleArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", background: "#1abc9c", borderRadius: "100%" }}
            onClick={onClick}
          />
        );
      }
      var settings = {
        className: "slides",
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <SampleArrow />,
        prevArrow: <SampleArrow />,
        pauseOnHover: true
        // cssEase: "linear"
      };
  return (
    <div className='event'>
        <h1 className='event-name'>Ganesh Puja : A Celebration of Unity and Devotion</h1>
        <div className="slider-container car3-container">
          <Slider {...settings}>
            <div className="slides">
              <img className="car2" src={gp1}></img>
            </div>
            <div className="slides">
              <img className="car2" src={gp2}></img>
            </div>


          </Slider>
        </div>

        <p>Ganesh Puja unites devotees in colorful celebrations, echoing with chants of "Ganpati Bappa Morya" and "Jai Ganesh Deva."

          Homes and pandals adorned with vibrant decorations beckon worshippers to offer prayers and offerings of modaks and durva grass, seeking Lord Ganesh's blessings for prosperity and success.

          This sacred occasion transcends religious boundaries, fostering a sense of unity and brotherhood among Odias of diverse backgrounds.

          As devotees immerse themselves in the festivities, they draw inspiration from Lord Ganesh's virtues of humility, compassion, and resilience.

          May the divine grace of Lord Ganesh illuminate the world, dispelling obstacles and ushering in joy, prosperity, and peace.

          Happy Ganesh Puja! Jai Ganesh!
        </p>


      </div>
  )
}

export default Event2