import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imgx from "../media/home/1.jpg"

const Carousel = ({images}) => {
    console.log(images)
    function SampleArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", background: "#1abc9c", borderRadius: "100%" }}
            onClick={onClick}
          />
        );
      }
    var settings = {
        className: "slides",
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <SampleArrow />,
        prevArrow: <SampleArrow />,
        pauseOnHover: true
        // cssEase: "linear"
    };
  return (
    <div className="contents slider-container">
                    <Slider {...settings}>
                        {images.map((image,key)=>{
                            <div className="slides">
                                <img className="car" src={image} alt="hellp"></img>
                            </div>
                        })}
                    </Slider>
                </div>
  )
}

export default Carousel