const finStu = [
    {
      name: "Mr. Barada Prasanna Nayak",
      phone: "7978250855"
    },
    {
      name: "Mr. Dibyajyoti Arukha",
      phone: "7683817634"
    }
  ];
  
export default finStu;