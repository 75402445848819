import React from 'react';
import "./styles.css";
import Slider from "react-slick";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import utkal1 from "../media/events/Utkal divas 2023 Photos-20240328T084351Z-001/DSC_0014.JPG"
import utkal2 from "../media/events/Utkal divas 2023 Photos-20240328T084351Z-001/DSC_0016.JPG"
import utkal3 from "../media/events/Utkal divas 2023 Photos-20240328T084351Z-001/DSC_0042.JPG"
import utkal4 from "../media/events/Utkal divas 2023 Photos-20240328T084351Z-001/DSC_0048.JPG"
import utkal5 from "../media/events/Utkal divas 2023 Photos-20240328T084351Z-001/DSC_0061.JPG"
import utkal6 from "../media/events/Utkal divas 2023 Photos-20240328T084351Z-001/DSC_0063.JPG"
import utkal7 from "../media/events/Utkal divas 2023 Photos-20240328T084351Z-001/DSC_0091.JPG"
import utkal8 from "../media/events/Utkal divas 2023 Photos-20240328T084351Z-001/DSC_0148.JPG"
import utkal9 from "../media/events/Utkal divas 2023 Photos-20240328T084351Z-001/DSC_0163.JPG"
import utkal10 from "../media/events/Utkal divas 2023 Photos-20240328T084351Z-001/DSC_7377.JPG"

const Event1 = () => {
    function SampleArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", background: "#1abc9c", borderRadius: "100%" }}
            onClick={onClick}
          />
        );
      }
      var settings = {
        className: "slides",
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <SampleArrow />,
        prevArrow: <SampleArrow />,
        pauseOnHover: true
        // cssEase: "linear"
      };
  return (
    <div className='event'>
        <h1 className='event-name'>Utkal Divas: Celebrating Odisha's Pride</h1>
        <div className='car2-container'>
        <div className="slider-container ">
          <Slider {...settings}>
            <div className="slides">
              <img className="car2" src={utkal1}></img>
            </div>
            <div className="slides">
              <img className="car2" src={utkal2}></img>
            </div>
            <div className="slides">
              <img className="car2" src={utkal3}></img>
            </div>
            <div className="slides">
              <img className="car2" src={utkal4}></img>
            </div>
            <div className="slides">
              <img className="car2" src={utkal5}></img>
            </div>
            <div className="slides">
              <img className="car2" src={utkal6}></img>
            </div>
            <div className="slides">
              <img className="car2" src={utkal7}></img>
            </div>
            <div className="slides">
              <img className="car2" src={utkal8}></img>
            </div>
            <div className="slides">
              <img className="car2" src={utkal9}></img>
            </div>
            <div className="slides">
              <img className="car2" src={utkal10}></img>
            </div>


          </Slider>
        </div>
        </div>
        
        <p>Utkal Divas, commemorating the birth of Odisha, unfurls a tapestry of pride and patriotism in every heart. On this auspicious day, we pay homage to the valor of our forefathers and the resilience of our land.

          From the serene shores of Puri to the majestic peaks of the Eastern Ghats, Odisha's beauty knows no bounds. Its rich heritage, adorned with vibrant festivals and timeless traditions, reflects the essence of its people.

          Utkal Divas serves as a poignant reminder of Odisha's journey towards progress and prosperity, guided by the vision of its illustrious leaders and fueled by the aspirations of its citizens.

          As we celebrate this historic day, let us reaffirm our commitment to uphold the values of unity, diversity, and inclusivity that define the spirit of Odisha.

          Let the echoes of "Bande Utkal Janani" resonate across the land, igniting a sense of pride and belonging in every Odia heart, both near and far.

          Happy Utkal Divas! Jai Jagannath!
        </p>


      </div>
  )
}

export default Event1