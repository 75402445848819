import React, { useState } from 'react'
import "./styles.css";

import utkal1 from "../media/events/Utkal divas 2023 Photos-20240328T084351Z-001/DSC_0014.JPG"
import utkal2 from "../media/events/Utkal divas 2023 Photos-20240328T084351Z-001/DSC_0016.JPG"
import utkal3 from "../media/events/Utkal divas 2023 Photos-20240328T084351Z-001/DSC_0042.JPG"
import utkal4 from "../media/events/Utkal divas 2023 Photos-20240328T084351Z-001/DSC_0048.JPG"
import utkal5 from "../media/events/Utkal divas 2023 Photos-20240328T084351Z-001/DSC_0061.JPG"
import utkal6 from "../media/events/Utkal divas 2023 Photos-20240328T084351Z-001/DSC_0063.JPG"
import utkal7 from "../media/events/Utkal divas 2023 Photos-20240328T084351Z-001/DSC_0091.JPG"
import utkal8 from "../media/events/Utkal divas 2023 Photos-20240328T084351Z-001/DSC_0148.JPG"
import utkal9 from "../media/events/Utkal divas 2023 Photos-20240328T084351Z-001/DSC_0163.JPG"
import utkal10 from "../media/events/Utkal divas 2023 Photos-20240328T084351Z-001/DSC_7377.JPG"
import gp1 from "../media/events/Ganesh puja 2023-20240328T105134Z-001/20230919_221938.jpg"
import gp2 from "../media/events/Ganesh puja 2023-20240328T105134Z-001/20230919_222026.jpg"
import sp1 from "../media/events/Saraswati puja 2024-20240328T105135Z-001/20240214_112506.jpg"
import sp2 from "../media/events/Saraswati puja 2024-20240328T105135Z-001/20240214_190239.jpg"
import sp3 from "../media/events/Saraswati puja 2024-20240328T105135Z-001/20240214_224420.jpg"
import sp4 from "../media/events/Saraswati puja 2024-20240328T105135Z-001/20240214_224803.jpg"
import sp5 from "../media/events/Saraswati puja 2024-20240328T105135Z-001/20240215_115009.jpg"

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"


const Gallery = () => {
  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState('');
  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  }
  return (
    <div className='contents'>
      <div className={model ? "model open" : "model"}>
        <img src={tempImgSrc} />
      </div>
      <h1>Archive</h1>
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
      >
        <Masonry>
          <img src={utkal1} className='gallery-img' onClick={() => getImg({ utkal1 })} />
          <img src={utkal2} className='gallery-img' onClick={() => getImg({ utkal2 })} />
          <img src={utkal3} className='gallery-img' onClick={() => getImg({ utkal3 })} />
          <img src={utkal4} className='gallery-img' onClick={() => getImg({ utkal4 })} />
          <img src={utkal5} className='gallery-img' onClick={() => getImg({ utkal5 })} />
          <img src={utkal6} className='gallery-img' onClick={() => getImg({ utkal6 })} />
          <img src={utkal7} className='gallery-img' onClick={() => getImg({ utkal7 })} />
          <img src={utkal8} className='gallery-img' onClick={() => getImg({ utkal8 })} />
          <img src={utkal9} className='gallery-img' onClick={() => getImg({ utkal9 })} />
          <img src={utkal10} className='gallery-img' onClick={() => getImg({ utkal10 })} />
          <img src={gp1} className='gallery-img' onClick={() => getImg({ gp1 })} />
          <img src={gp2} className='gallery-img' onClick={() => getImg({ gp2 })} />
          <img src={sp1} className='gallery-img' onClick={() => getImg({ sp1 })} />
          <img src={sp2} className='gallery-img' onClick={() => getImg({ sp2 })} />
          <img src={sp3} className='gallery-img' onClick={() => getImg({ sp3 })} />
          <img src={sp4} className='gallery-img' onClick={() => getImg({ sp4 })} />
          <img src={sp5} className='gallery-img' onClick={() => getImg({ sp5 })} />

        </Masonry>
      </ResponsiveMasonry>
      {/* <div className='gallery'>
        <div className='pics' onClick={() => getImg({ utkal1 })}><img src={utkal1} className='gallery-img' /></div>
        <div className='pics' onClick={() => getImg({ utkal2 })}><img src={utkal2} className='gallery-img' /></div>
        <div className='pics' onClick={() => getImg({ utkal3 })}><img src={utkal3} className='gallery-img' /></div>
        <div className='pics' onClick={() => getImg({ utkal4 })}><img src={utkal4} className='gallery-img' /></div>
        <div className='pics' onClick={() => getImg({ utkal5 })}><img src={utkal5} className='gallery-img' /></div>
        <div className='pics' onClick={() => getImg({ utkal6 })}><img src={utkal6} className='gallery-img' /></div>
        <div className='pics' onClick={() => getImg({ utkal7 })}><img src={utkal7} className='gallery-img' /></div>
        <div className='pics' onClick={() => getImg({ utkal8 })}><img src={utkal8} className='gallery-img' /></div>
        <div className='pics' onClick={() => getImg({ utkal9 })}><img src={utkal9} className='gallery-img' /></div>
        <div className='pics' onClick={() => getImg({ utkal10 })}><img src={utkal10} className='gallery-img' /></div>
        <div className='pics' onClick={() => getImg({ gp1 })}><img src={gp1} className='gallery-img' /></div>
        <div className='pics' onClick={() => getImg({ gp2 })}><img src={gp2} className='gallery-img' /></div>
        <div className='pics' onClick={() => getImg({ sp1 })}><img src={sp1} className='gallery-img' /></div>
        <div className='pics' onClick={() => getImg({ sp2 })}><img src={sp2} className='gallery-img' /></div>
        <div className='pics' onClick={() => getImg({ sp3 })}><img src={sp3} className='gallery-img' /></div>
        <div className='pics' onClick={() => getImg({ sp4 })}><img src={sp4} className='gallery-img' /></div>
        <div className='pics' onClick={() => getImg({ sp5 })}><img src={sp5} className='gallery-img' /></div>


      </div> */}

    </div>
  )
}

export default Gallery