import React from 'react'

const PersonCard = ({ img, name, dept, email }) => {
    return (

        <div className='card'>
            <div className='top'>
                <img className="img" src={img}></img>
            </div>
            <div>
                <hr></hr>
            </div>

            <div className='bottom'>
                <p className="card-name">{name}</p>
                <p className="card-text">{dept}</p>
                <p className="card-text">{email}</p>
            </div>


        </div>


    )
}

export default PersonCard