import React from 'react';
import "./styles.css";
import Slider from "react-slick";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sp1 from "../media/events/Saraswati puja 2024-20240328T105135Z-001/20240214_112506.jpg"
import sp2 from "../media/events/Saraswati puja 2024-20240328T105135Z-001/20240214_190239.jpg"
import sp3 from "../media/events/Saraswati puja 2024-20240328T105135Z-001/20240214_224420.jpg"
import sp4 from "../media/events/Saraswati puja 2024-20240328T105135Z-001/20240214_224803.jpg"
import sp5 from "../media/events/Saraswati puja 2024-20240328T105135Z-001/20240215_115009.jpg"

const Event3 = () => {
    function SampleArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", background: "#1abc9c", borderRadius: "100%" }}
            onClick={onClick}
          />
        );
      }
      var settings = {
        className: "slides",
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <SampleArrow />,
        prevArrow: <SampleArrow />,
        pauseOnHover: true
        // cssEase: "linear"
      };
  return (
    <div className='event'>
        <div className='event-name'><h1 >Saraswati Puja: Honoring the Goddess of Knowledge and Wisdom</h1></div>

        <div className="slider-container car2-container">
          <Slider {...settings}>
            <div className="slides">
              <img className="car2" src={sp1}></img>
            </div>
            <div className="slides">
              <img className="car2" src={sp2}></img>
            </div>
            <div className="slides">
              <img className="car2" src={sp3}></img>
            </div>
            <div className="slides">
              <img className="car2" src={sp4}></img>
            </div>
            <div className="slides">
              <img className="car2" src={sp5}></img>
            </div>

          </Slider>
        </div>
        <p>Saraswati Puja, a cherished festival in Odisha, celebrates the embodiment of knowledge, arts, and wisdom, Goddess Saraswati.

          Held with great fervor, it marks a time of reverence and devotion as students, scholars, and artists seek the blessings of the divine Goddess for success in their endeavors.

          Homes, schools, and colleges are adorned with colorful decorations, and beautifully crafted idols of Saraswati are worshipped with offerings of flowers, fruits, and sweets.

          The melodious sounds of hymns and chants fill the air, as devotees gather to invoke Saraswati's grace and guidance in their pursuit of learning and creativity.

          Children are initiated into the world of letters, with pens and books placed at the feet of the Goddess, symbolizing the importance of education and knowledge.

          Saraswati Puja transcends religious boundaries, embodying the spirit of inclusivity and enlightenment, welcoming all to partake in its blessings.

          As the gentle breeze carries the fragrance of incense and the echoes of prayers, Saraswati Puja brings forth a sense of renewal and inspiration, rejuvenating minds and hearts alike.

          May Goddess Saraswati bestow her blessings upon us all, nurturing our intellect, creativity, and wisdom on this auspicious day.

          Happy Saraswati Puja! Jai Saraswati!
        </p>



      </div>
  )
}

export default Event3