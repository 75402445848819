import React from "react";
import "./styles.css";
import img1 from "../media/4904d7e3-512c-408b-bad4-2d3c7bb32e3a.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import car1 from "../media/home/1.jpg"
import car2 from "../media/home/2.jpg"
import car3 from "../media/home/3.jpg"
import car4 from "../media/home/4.jpg"
import car5 from "../media/home/5.JPG"
import Carousel from "./Carousel";
import adFac from "../data/adFac";
import OrganizerCard from "./OrganizerCard";


function Home() {
    // const images = require.context('../media/home', true);
    // const imageList = images.keys().map(image => images(image))
    // const imager=[car1,car2,car3,car4,car5]
    function SampleArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "#1abc9c", borderRadius: "100%" }}
                onClick={onClick}
            />
        );
    }
    var settings = {
        className: "slides",
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <SampleArrow />,
        prevArrow: <SampleArrow />,
        pauseOnHover: true
        // cssEase: "linear"
    };
    return (
        <>

            <div className="content">
                <div className="contents slider-container car1-container">
                    <Slider {...settings}>
                        <div className="slides">
                            <img className="car" src={car1}></img>
                        </div>
                        <div>
                            <img className="car" src={car2}></img>
                        </div>
                        <div>
                            <img className="car" src={car3}></img>
                        </div>
                        <div>
                            <img className="car" src={car4}></img>
                        </div>
                        <div>
                            <img className="car" src={car5}></img>
                        </div>

                    </Slider>
                </div>

                <div className="contents"><h1>About Us: Odia Parivaar</h1>
                    <p>Welcome to Odia Parivar, a vibrant community dedicated to preserving and celebrating the rich tapestry of Odia culture and tradition. With deep roots steeped in history and a legacy of resilience, Odisha, the land of temples, art, and literature, beckons you to join us on a journey of exploration and enlightenment.

                        Our club serves as a beacon of pride for Odias across the globe, honoring our ancestors' enduring legacy while embracing the challenges of the modern world. From the intricate carvings of the Sun Temple at Konark to the soul-stirring poetry of Jayadeva, our cultural heritage is as diverse as it is enchanting.

                        At Odia Parivar, we strive to cultivate a sense of belonging and camaraderie among our members, fostering an environment where every voice is heard and every talent is cherished. Through a myriad of events, workshops, and cultural exchanges, we aim to ignite a passion for our heritage within the hearts of the youth, empowering them to become torchbearers of Odia culture in the 21st century.

                        Join us as we embark on a journey to rediscover our roots, reignite the flame of tradition, and forge new connections that transcend borders and generations. Together, let us write the next chapter in the saga of Odia excellence, inspired by the past, and empowered by the promise of a brighter future.

                        Odia Parivar: Where Tradition Meets Tomorrow.
                    </p></div>
                <div className="contents">
                    <h1>Meet the Advisory Team</h1>
                    <div className="people">
                        {adFac.map((person) =>
                            <OrganizerCard
                                name={person.name}
                                img={person.img}
                                dept={person.department}
                                email={person.email}
                                size="small"
                            />
                        )}
                    </div>
                </div>
                <div className="contents">
                    <h1>From the desk of Faculty-in-Charge</h1>
                    <img src={img1} className="img1" />
                    <h3>Prof. Premalata Jena</h3>
                    <p style={{ "marginTop": "-1rem" }}>Associate Professor, Dept. of Electrical Engineering</p>
                    <hr style={{ "marginTop": "-0.5rem" }} />
                    <p style={{ "marginTop": "-1rem" }}>Our club serves as a beacon of pride for Odias across the globe, honoring our ancestors' enduring legacy while embracing the challenges of the modern world. From the intricate carvings of the Sun Temple at Konark to the soul-stirring poetry of Jayadeva, our cultural heritage is as diverse as it is enchanting.</p>
                </div>

            </div>
        </>
    )
}

export default Home;