import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import People from "./components/People";
import Events from "./components/Events";
import Gallery from "./components/Gallery";
import Organizers from "./components/Organizers";
import "./components/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Navbar />
          
          
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/people" element={<People />}/>
          <Route path="/events" element={<Events />}/>
          <Route path="/gallery" element={<Gallery />}/>
          <Route path="/organizers" element={<Organizers />}/>
          
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
