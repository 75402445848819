import React from 'react';
import "./styles.css";

import Event1 from './Event1';
import Event2 from './Event2';
import Event3 from './Event3';

const Events = () => {
  
  return (
    <div className='contents'>
      <Event1 />
      <Event2 />
      <Event3 />
      
      

    </div>
  )
}

export default Events