import p1 from "../media/people/1.jpg"
import abhishekKumarbehra from "../media/people/Faculty photos/Abhishek Behera.JPG"
import abhishekSamantray from "../media/people/Faculty photos/abhishek samantray.JPG"
import abinashSwain from "../media/people/Faculty photos/abinash swain.JPG"
import amalenduPatnaik from "../media/people/Faculty photos/Amalendu patnaik.JPG"
import ameeyaKNayak from "../media/people/Faculty photos/ameeya k nayak.JPG"
import anindyaJMishra from "../media/people/Faculty photos/Anindya J Mishra.JPG"
import aruniMohaptra from "../media/people/Faculty photos/Aruni mohaptra.JPG"
import prasadDas from "../media/people/Faculty photos/bishnu prasad das.JPG"
import dBharat from "../media/people/Faculty photos/D bharat.JPG"
import debiPrasannaSahoo from "../media/people/Faculty photos/debi prasanna sahoo.JPG"
import diptimayeeNayak from "../media/people/Faculty photos/diptimayee nayak.JPG"
import gjChakrapani from "../media/people/Faculty photos/G.J. Chakrapani.jpg"
import jagdishPSahoo from "../media/people/Faculty photos/jagdish p sahoo.JPG"
import jogendraKumarNayak from "../media/people/Faculty photos/jogendra kumar nayak.JPG"
import kirtiBhusanMishra from "../media/people/Faculty photos/kirti bhusan mishra.JPG"
import lalatenduKDas from "../media/people/Faculty photos/lalatendu k das.JPG"
import manoranjanParida from "../media/people/Faculty photos/manoranjan Parida.JPG"
import mohitMohanty from "../media/people/Faculty photos/mohit prakash mohanty.JPG"
import npPadhy from "../media/people/Faculty photos/N P Padhy.JPG"
import pKSahoo from "../media/people/Faculty photos/P K sahoo.JPG"
import paritoshMohanty from "../media/people/Faculty photos/paritosh mohanty.jpg"
import pitambarPati from "../media/people/Faculty photos/Pitambar pati.JPG"
import pranitaSarangi from "../media/people/Faculty photos/pranita p sarangi.JPG"
import pratapChandraMohanty from "../media/people/Faculty photos/pratap chandra mohanty.JPG"
import premalataJena from "../media/people/Faculty photos/premalata jena.JPG"
import pyariMohanPradhan from "../media/people/Faculty photos/pyari mohan pradhan.JPG"
import rajibKumarPanigrahi from "../media/people/Faculty photos/rajib kumar panigrahi.JPG"
import ramanujaPanigrahi from "../media/people/Faculty photos/ramanuja panigrahi.JPG"
import saradaPPradhan from "../media/people/Faculty photos/sarada p pradhan.JPG"
import sharmiliDas from "../media/people/Faculty photos/Sharmili das.JPG"
import sibaPatro from "../media/people/Faculty photos/siba patro.JPG"
import simanchalPadhy from "../media/people/Faculty photos/simanchal padhy.JPG"
import sonalishaRay from "../media/people/Faculty photos/sonalisha ray.JPG"
import sudhakarSubudhi from "../media/people/Faculty photos/sudhakar subudhi.JPG"
import sumeetMishra from "../media/people/Faculty photos/sumeet mishra.JPG"
import ushaLenka from "../media/people/Faculty photos/usha lenka.JPG"
import dpKanungo from "../media/people/Faculty photos/DP kanungo.jpg"

const adFac = [
    {
        name: "Prof. Manoranjan Parida",
        department: "Civil Engineering",
        img: manoranjanParida,
        email: "mprdafce@iitr.ac.in, mparida@gmail.com",
        phone: ""
      },
      {
        name: "Prof. N.P. Padhy",
        department: "Electrical Engineering",
        img: npPadhy,
        email: "nppadhy@ee.iitr.ac.in",
        phone: ""
      },
      {
        name: "Prof. D.P. Kanungo",
        department: "", // Department information not provided
        img: dpKanungo,
        email: "",
        phone: ""
      },
      {
        name: "Prof. Amalendu Patnaik",
        department: "", // Department information not provided
        img: amalenduPatnaik,
        email: "",
        phone: ""
      },
      {
        name: "Prof. Paritosh Mohanty",
        department: "", // Department information not provided
        img: paritoshMohanty,
        email: "",
        phone: ""
      },
      {
        name: "Prof. Usha Lenka",
        department: "", // Department information not provided
        img: ushaLenka,
        email: "",
        phone: ""
      },
      {
        name: "Prof. Rajib Kumar Panigrahi",
        department: "", // Department information not provided
        img: rajibKumarPanigrahi,
        email: "",
        phone: ""
      },
      {
        name: "Prof. Anindya Jayanta Mishra",
        department: "Humanities and Social Sciences",
        img: anindyaJMishra,
        email: "anindya.mishra@hs.iitr.ac.in",
        phone: ""
      },
      {
        name: "Prof. Jogendra Kumar Nayak",
        department: "Mathematics",
        img: jogendraKumarNayak,
        email: "ameeya.nayak@ma.iitr.ac.in",
        phone: ""
      }
  ];
export default adFac;