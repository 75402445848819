import React from 'react'
import PersonCard from './PersonCard'
import people from "../data/people"
import p1 from "../media/people/1.jpg"



const People = () => {
  return (
    <div className='contents'>
        <h1>Meet the people</h1>
        <div className=' people'>
        {people.map(person=>
            <PersonCard
            name={person.name}
            img={person.imgsrc}
            dept={person.department}
            email={person.email}
            />
        )}
        </div>
    </div>
    
  )
}

export default People