const adStu=[
    {
        name: "Mr. Jagannath Samantaray",
        department: "", // Department not provided
        img: "", // Leave this blank for now
        email: "", // Fill this with appropriate email address
        phone: "", // Fill this with appropriate phone number
    },
    {
        name: "Mr. Soumya Darshan Panda",
        department: "", // Department not provided
        img: "",
        email: "",
        phone: "",
    },
    {
        name: "Ms. Pratibha Kiran Giri",
        department: "", // Department not provided
        img: "",
        email: "",
        phone: "",
    },
    {
        name: "Mr. Jyoti Ranjan Dash",
        department: "", // Department not provided
        img: "",
        email: "",
        phone: "",
    },
    {
        name: "Mr. Prasanta Kumar Mohanty",
        department: "", // Department not provided
        img: "",
        email: "",
        phone: "",
    }
]

export default adStu;